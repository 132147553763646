import { defineStore } from "pinia";
import { CURRENT_CONFIG } from "../api/http/config"

export default defineStore({
  id: "app",
  state: () => ({
    device_sn: null,
    child_device_sn: null,
    device: null,
    lentSelection: null,
    cameraSelected: {
      "index": CURRENT_CONFIG.camera
      //"index": "53-0-0"
    },
    agl: -999,
    mqtt: {
      client: null,
      isConnected: false,
    },
    mqttDisconnected: true,
    gatewayConnected: false,
    droneConnected: false,
    telemetry: {},
    google: null,
    mapContainer: null,
    map: null,
    droneMarker: null,
    droneCoordinates: {"lat": 0, "lng": 0},
    gatewayMarker: null,
    gatewayCoordinates: {"lat": 0, "lng": 0},
    selectedWayline: null,
    selectedWaylineObject: null,
    devices: [],
    videoUrl: null,
    markerDetections: [],
    autoCenter: false,
  }),

  getters: {
    getDeviceSn() {
      return this.device_sn;
    },
    getChildDeviceSn() {
      return this.child_device_sn;
    },
    getDevice() {
      return this.device;
    },
  },
  
  actions: {
    toggleAutoCenter() {
      this.autoCenter = !this.autoCenter;
    },
    add_detections(data) {
      data["drone_latitude"] = this.droneCoordinates.lat;
      data["drone_longitude"] = this.droneCoordinates.lng;
      this.markerDetections.push(data);
    },
    cleanEvents() {
      this.markerDetections = [];
    },
    setDeviceSn(sn) {
      this.device_sn = sn;
    },
    setChildDeviceSn(sn) {
      this.child_device_sn = sn;
    },
    setDevice(device) {
      this.device = device;
    },
    setMQTTClient(client) {
      this.mqtt.client = client;
    },
    centerGatewayMap() {
      this.map.setCenter(this.gatewayCoordinates);
      this.map.setZoom(20);
    },
    centerDroneMap() {
      this.map.setCenter(this.droneCoordinates);
      this.map.setZoom(20);
    },
  },
});
